import {Injectable, OnDestroy} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WindowSizeService implements  OnDestroy  {
  isWebView = window.innerWidth >= 639;
  isSmallTabletView = window.innerWidth >= 638 && window.innerWidth <= 767;
  isSmallMobileView = window.innerWidth <= 412;
  isMobileView = window.innerWidth >= 413 && window.innerWidth <= 638;
  constructor() {
    window.addEventListener('resize', this.onResize.bind(this));
  }
  ngOnDestroy() {
    window.removeEventListener('resize', this.onResize.bind(this));
  }

  private onResize() {
    this.isWebView = window.innerWidth >= 639;
    this.isSmallTabletView = window.innerWidth >= 638 && window.innerWidth <= 767;
    this.isSmallMobileView = window.innerWidth <= 412;
    this.isMobileView = window.innerWidth >= 413 && window.innerWidth <= 638;
  }
}
