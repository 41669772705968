
<mat-toolbar color="primary" class="p-y-4"  [ngClass]="{'custom-dark-bg':customClass , 'toolbar-wrap': wrap}" style="position: relative">
  @if (showBackButton) {
    @if (preventBack) {
      <button mat-icon-button>
        <mat-icon> ballot </mat-icon>
      </button>
    } @else {
      @if(routeLink) {
        <button mat-icon-button [routerLink]="routeLink">
          <mat-icon>
            {{ _translate.currentLang !== "ar" ? "arrow_back" : "arrow_forward" }}
          </mat-icon>
        </button>
      } @else {
        <button mat-icon-button (click)="emitAction()">
          <mat-icon>
            {{ _translate.currentLang !== "ar" ? "arrow_back" : "arrow_forward" }}
          </mat-icon>
        </button>
      }
    }
  }

  @if (isWebView) {
    <span>{{ title | translate }}</span>
  } @else {
    <span class="f-s-14">{{ title | translate }}</span>
  }

  <div class="spacer"></div>
  <ng-content></ng-content>
</mat-toolbar>



