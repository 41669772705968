import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {MatToolbar} from '@angular/material/toolbar';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {Location, NgClass, NgStyle} from '@angular/common';
import {RouterLink, Router} from '@angular/router';
import {WindowSizeService} from '../../../../shared/service/window-size.service';

@Component({
  selector: 'screen-toolbar',
  standalone: true,
  imports: [
    MatIcon,
    MatIconButton,
    MatToolbar,
    TranslatePipe,
    RouterLink,
    NgClass,
    NgStyle
  ],
  templateUrl: './screen-toolbar.component.html',
  styleUrl:'screen-toolbar.component.scss'
})
export class ScreenToolbarComponent {
  @Input() preventBack:boolean = false;
  @Input() customClass:boolean = false;
  @Input() wrap:boolean = false;
  @Input() title:string;
  @Input() routeLink:string;
  @Output() action:EventEmitter<any> = new EventEmitter();
  @Input() showBackButton: boolean = true;
  constructor(
    private locations: Location,
    public _translate: TranslateService,
    private windowSizeService: WindowSizeService,
    private router: Router
  ) { }
  back() {
    if (this.routeLink) {
      this.router.navigate([this.routeLink]);
    } else {
      this.locations.back();
    }
  }

  emitAction() {
    this.action.emit();
  }

  get isWebView() {
    return this.windowSizeService.isWebView;
  }
}
